





































import Vue from "vue";
import TierTypes from "./tiers";
import { Action, Getter } from "vuex-class";
import { Product, SelectItem } from "@/core/models";
import { EmailsInput } from "@/components/common";
import { Component, Prop } from "vue-property-decorator";
import BillingDetailsForm from "./BillingDetailsForm.vue";

@Component({ components: { BillingDetailsForm, EmailsInput } })
export default class UpgradeCompany extends Vue {
  @Prop({ default: () => 0 }) id!: number;
  @Prop({ default: () => false }) disabled!: boolean;
  @Prop({ default: () => TierTypes.basic }) currentPlan!: string;
  @Getter("company/upgrading") loading!: boolean;
  @Getter("products/products") products!: Product[];
  @Action("company/upgrade") upgrade!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  dialog = false;
  newPlan = this.currentPlan;
  get items() {
    return this.products
      .filter(p => p.active)
      .map(p => ({ text: p.name, value: p.monthlyPrice } as SelectItem));
  }

  upgradeCancel() {
    this.dialog = false;
    this.newPlan = this.currentPlan;
  }
  upgradeConfirm() {
    if (this.loading) return;
    if (this.currentPlan === this.newPlan) {
      const msg = this.$t("company.same").toString();
      this.displaySnackbar(msg);
      return;
    }

    const data = { id: this.id, newPlan: this.newPlan };
    this.upgrade(data);
    this.dialog = false;
  }
}
