import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"elevation":"0","color":"primary","loading":_vm.loading,"disabled":_vm.disabled || _vm.loading}},on),[_c('span',[_vm._v(_vm._s(_vm.$t("company.changep")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("company.selectp")))]),_c(VCardText,[_c(VSelect,{attrs:{"label":"Plan","items":_vm.items},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2"},[_vm._v(" "+_vm._s(_vm.$t("global.nodata"))+" ")])]},proxy:true}]),model:{value:(_vm.newPlan),callback:function ($$v) {_vm.newPlan=$$v},expression:"newPlan"}})],1),_c(VCardActions,{staticClass:"px-6"},[_c(VBtn,{attrs:{"elevation":"0"},on:{"click":_vm.upgradeCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.upgradeConfirm}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.confirm")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }